/* ---------------------------------------------------------------------------- */
/* -------------------------------- ANIMATION --------------------------------- */
/* ---------------------------------------------------------------------------- */


@keyframes fadeInLeft {
  from {
    opacity: 0;
    /* -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0); */
  }

  to {
    opacity: 1;
    /* -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); */
  }
}

.fadeInLeft {
	animation-duration: 0s;
	-webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}


/* ---------------------------------------------------------------------------- */
/* --------------------------- main center WORK block ------------------------- */
/* ---------------------------------------------------------------------------- */

.work {
	display: none;
}

.work.show {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-start;
  align-items: flex-start;
  width: 856px;
	margin-top: 112px;
	/* margin-bottom: 56px; */
	/* margin-left: 224px; */
	/* flex-grow: 1; */
}

.workList {
	display: flex;
	position: relative;
	justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
	margin-top: 56px;
	/* height: 56px; */
	width: inherit;
	/* border-bottom: 1px solid var(--line); */
}