/* ---------------------------------------------------------------------------- */
/* -------------------------------- ANIMATION --------------------------------- */
/* ---------------------------------------------------------------------------- */


@keyframes fadeInLeft {
  from {
    opacity: 0;
    /* -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0); */
  }

  to {
    opacity: 1;
    /* -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); */
  }
}

.fadeInLeft {
	animation-duration: 0s;
	-webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}


/* ---------------------------------------------------------------------------- */
/* ------------------------- main center CONTACTS block ----------------------- */
/* ---------------------------------------------------------------------------- */


.contact {
	display: none;
}

.contact.show {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/* width: 808px; */
	margin-top: 112px;
	margin-bottom: 112px;
	/* margin-left: 224px; */
	/* flex-grow: 1; */
}

.contactInfo {
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 40px;
	/* height: 56px; */
	/* width: 376px; */
	/* border-bottom: 1px solid var(--line); */
}

.contactFirst,
.contactSecond {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/* height: 56px; */
	/* width: 376px; */
	/* border-bottom: 1px solid var(--line); */
}

.contactSecond {
	margin-left: 56px;
}

.mail,
.phone,
.time,
.social,
.location,
.mailData,
.phoneData,
.timeData,
.vk,
.fb,
.telegram,
.locationData {
	position: relative;
	width: 400px;
	border-bottom: 1px solid var(--line);
	padding-top: 16px;
	padding-bottom: 16px;
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0.2pt;
	line-height: 23px;
	margin-top: 0px;
	margin-bottom: 0px;
}

.mail,
.phone,
.time,
.social,
.location {
	color: var(--text-hover);
}

.mailData,
.phoneData,
.timeData,
.telegram,
.locationData {
	color: var(--text);
}

.vk a,
.fb a {
	color: var(--text);
	transition-duration: 0.4s;
}

.vk a:hover,
.fb a:hover {
	color: var(--text-hover);
}

.image {
	margin-top: 56px;
	margin-bottom: 56px;
	border-radius: 4px;
}