/* @import "animate.css"; */

@keyframes loadContent {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@keyframes loadLine {
	0% { width: 0%; }
	/* 30% { width: 100%; } */
}

:root {
  --background: #f2f2f3;
	--text: #252528;
	--text-hover: #6e6e77;
	--text-light: #95959d;
	--line: #d7d7da;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-family: 'Montserrat', sans-serif;
  /* font-family: 'Roboto Slab', serif; */
  /* Roboto Slab: 100 300 400 700 */
  /* font-family: 'Open Sans', sans-serif; */
	font-size: 14px;
	font-weight: 400;
	/* letter-spacing: 0pt; */
  color: var(--text-light);
  opacity: 1; /* Firefox */
}

html,
body,
#root {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	font-family: 'Montserrat', sans-serif;
	/* font-family: 'Roboto Slab', serif; */
	/* font-family: 'Open Sans', sans-serif; */
	background-color: var(--background);
}

h1 {
	/* text-transform: uppercase; */
	font-weight: 700;
	font-size: 56px;
	line-height: 80px;
	letter-spacing: 0,4pt;
	color: var(--text);
	margin-top: 0px;
	margin-bottom: 32px;
	/* transition-duration: 0.5s; */
}

h2 {
	/* text-transform: uppercase; */
	font-weight: 400;
	font-size: 28px;
	line-height: 40px;
	letter-spacing: 0pt;
	color: var(--text);
	margin-top: 0px;
	margin-bottom: 56px;
	/* transition-duration: 0.5s; */
}

p {
	font-weight: 500;
	font-size: 18px;
	letter-spacing: 0pt;
	line-height: 26px;
	color: var(--text);
	margin-top: 0px;
	margin-bottom: 0px;
	transition-duration: 0.5s;
}

a {
	text-decoration: none;
	cursor: pointer;
	color: var(--text);
}


/* ---------------------------------------------------------------------------- */
/* -------------------------------- CONTAINER --------------------------------- */
/* ---------------------------------------------------------------------------- */


.app-container-out {
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: stretch;
	max-width: 1440px;
	/* height: inherit; */
	min-height: 100%;
	background-color: var(--background);
	flex-grow: 1;
}

.app-container-in {
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-start;
	align-items: stretch;
	/* width: inherit; */
	/* height: inherit; */
	min-height: 100%;
	background-color: var(--background);
	flex-grow: 1;
}