.elem {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	/* width: fit-content; */
	/* height: 42px; */
	/* background-color: var(--day-light-color); */
	cursor: pointer;
	margin-bottom: 112px;
}

.elem:nth-child(2n+1) {
	margin-right: 56px;
}

.title,
.description {
	position: relative;
	width: 400px;
	font-size: 18px;
	/* letter-spacing: 0.2pt; */
	line-height: 23px;
}

.description {
	font-weight: 500;
	margin-top: 24px;
	color: var(--text-hover);
	/* color: var(--text); */
}

.title {
	text-transform: uppercase;
	font-weight: 700;
	margin-top: 10px;
	color: var(--text);
}

.image {
	/* margin-top: 40px;
	margin-bottom: 48px; */
	border-radius: 4px;
}