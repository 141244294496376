.menuButton {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	/* transform: rotate(-90deg); */
	/* width: 72px; */
	height: 42px;
	margin-top: 16px;
	margin-bottom: 16px;
	/* background-color: var(--day-light-color); */
	cursor: pointer;
}

.menuButtonText {
	z-index: 1;
	font-weight: 700;
	font-size: 28px;
	line-height: 28px;
	letter-spacing: 2pt;
	white-space: nowrap;
	color: var(--background);
	/* transform: rotate(-90deg); */
	text-transform: uppercase;
	/* margin-left: 56px; */
	/* cursor: pointer; */
	transition-duration: 0.4s;
}

.menuButtonText:hover {
	color: var(--text-light);
}