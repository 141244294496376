.footer {
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-start;
	align-items: stretch;
	width: inherit;
	min-height: 112px;
}

.footerLine {
	position: relative;
	margin-left: 0px;
	margin-right: 56px;
	height: 8px;
	background-color: var(--text);
}

.footerContainer {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	height: 104px;
}

.footerCopyright {
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	/* height: 56px; */
	/* margin-top: 24px; */
	margin-left: 56px;
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.2pt;
	line-height: 20px;
	/* color: var(--day-medium-text-color); */
	color: var(--text-light);
	margin-bottom: 0px;
	transition-duration: 1s;
}


/* ----------------------------------- lang ----------------------------------- */


.footerLang {
	display: flex;
	position: relative;
	justify-content: flex-end;
	align-items: center;
	/* height: 56px; */
	/* margin-top: 24px; */
	margin-right: 56px;

	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
}


.footerLangRus,
.footerLangEng {
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.2pt;
	line-height: 20px;
	/* color: var(--day-medium-text-color); */
	color: var(--text);
	margin-top: 0px;
	margin-bottom: 0px;
	transition-duration: 0.2s;
	cursor: pointer;
}

.footerLangRus:hover,
.footerLangEng:hover {
	color: var(--text-hover);
}