/* ANIMATION */

@keyframes showLine {
  0% {
    opacity: 1;
		height: 0px;
	}

	50% {
    opacity: 1;
		height: 88px;
  }

  100% {
    opacity: 1;
		height: 72px;
  }
}

.showLine {
	animation-duration: 0.2s;
	-webkit-animation-name: showLine;
	animation-name: showLine;
	-webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes hideLine {
  from {
    opacity: 1;
    height: 72px;
  }

  to {
    opacity: 1;
		height: 0px;
  }
}

.hideLine {
	animation-duration: 0.2s;
	-webkit-animation-name: hideLine;
	animation-name: hideLine;
	-webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


/* BUTTON */

.button {
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	min-width: 120px;
	height: 120px;
	z-index: 5;
}

.buttonText {
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 4px;
	color: var(--text);
	margin-bottom: 4px;
	transform: rotate(-90deg);
	white-space: nowrap;
	text-transform: uppercase;
	transition-duration: 0.2s;
	cursor: pointer;
	/* transition-delay: 0.2s; */
}

.buttonTextOpen {
	color: var(--background);
}

.buttonLine {
	position: absolute;
	margin-left: 11px;
	width: 1px;
	background-color: var(--text);
}

.buttonLineOpen {
	background-color: var(--background);
}