.header {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	width: inherit;
	min-height: 112px;
}

.headerContainer {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	height: 110px;
	/* font-size: 18px; */
	/* border-bottom: 1px solid black; */
	/* background-color: var(--white-color); */
}

.headerLine {
	position: relative;
	margin-left: 0px;
	margin-right: 56px;
	height: 2px;
	background-color: var(--text);
}

.headerAbout {
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	height: 56px;
	margin-left: 56px;
}

.headerAboutPic {
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	font-weight: 700;
	/* margin-left: 40px; */
	font-size: 18px;
	/* transition-duration: 0.5s; */
}

.headerAboutPic span {
	/* margin-left: 16px; */
	margin-right: 28px;
	width: 56px;
  height: 56px;
  border-radius: 50%;
	/* background-color: var(--text); */
	background-image: url(../img/IMG_5700_crop_square.jpg);
	background-repeat: no-repeat;
	background-position: 50% 45%;
	background-size: 130%;
}

.headerAboutName {
	font-weight: 700;
	font-size: 18px;
	letter-spacing: 0.2pt;
	line-height: 23px;
	color: var(--text);
	margin-top: 0px;
	margin-bottom: 0px;
	transition-duration: 1s;
}

.headerLang {
	display: flex;
	position: relative;
	justify-content: flex-end;
	align-items: center;
	/* height: 56px; */
	/* margin-top: 24px; */
	margin-right: 56px;
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
}


.headerLangRus,
.headerLangEng {
	font-weight: 700;
	font-size: 14px;
	letter-spacing: 0.2pt;
	line-height: 20px;
	/* color: var(--day-medium-text-color); */
	color: var(--text-light);
	margin-top: 0px;
	margin-bottom: 0px;
	transition-duration: 0.2s;
	cursor: pointer;
	transition-duration: 0.4s;
}

.headerLangRus:hover,
.headerLangEng:hover {
	color: var(--text-hover);
}