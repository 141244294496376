/* ANIMATION main */

@keyframes showMain {
  from {
		opacity: 0;
		-webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }

  to {
		opacity: 1;
		-webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }
}

.showMain {
	animation-duration: 0.2s;
	box-shadow: 0px 0px 40px 4px rgba(37, 37, 40, 0.66);
	visibility: visible;
	-webkit-animation-name: showMain;
	animation-name: showMain;
	-webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes hideMain {
  from {
		opacity: 1;
  }

  to {
		opacity: 0;
		-webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
	}
}

.hideMain {
	animation-duration: 0.2s;
	visibility: hidden;
	-webkit-animation-name: hideMain;
	animation-name: hideMain;
	-webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* ------------------------------- Menu ------------------------------ */

.menu {
	display: flex;
	position: fixed;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	min-width: 120px;
	/* overflow: hidden; */
	z-index: 1;
}

.main {
	display: flex;
	position: absolute;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 464px;
	height: 100%;
	background-color: rgba(37, 37, 40, 0.98);
	transition-duration: 0.2s;
}

.header {
	display: flex;
	position: absolute;
	justify-content: center;
	align-items: center;
	align-self: flex-start;
	width: inherit;
	height: 110px;
	z-index: 1;
}

.list {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 24px;
	flex-grow: 1;
}

.socialV,
.socialF,
.socialG,
.socialI {
	width: 44px;
	height: 32px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	cursor: pointer;
	transition-duration: 0.2s;
	margin-left: 16px;
	margin-right: 16px;
	transition-duration: 0.4s;
	z-index: 5;
}

.socialI {
	width: 56px;
}

.socialV { background-image: url(../img/social-v.svg); }
.socialF { background-image: url(../img/social-f.svg); }
.socialG { background-image: url(../img/social-g.svg); }
.socialI { background-image: url(../img/social-i.svg); }

.socialV:hover { background-image: url(../img/social-v-text-light.svg); }
.socialF:hover { background-image: url(../img/social-f-text-light.svg); }
.socialG:hover { background-image: url(../img/social-g-text-light.svg); }
.socialI:hover { background-image: url(../img/social-i-text-light.svg); }